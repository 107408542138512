import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const ThankyouPage = () => {
  return (
    <Layout>
      <SEO title="Thank you" />
      <div>
        <Container>
          <div id="content" className="content">
            <div className="header align-center">
              <h1>Thank you</h1>
              <p className="block-center" style={{ maxWidth: "740px" }}>
                Thank you for getting in touch. I endeavour to reply within 1
                working day.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default ThankyouPage
